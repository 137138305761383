<script>
import { ref, onMounted } from '@vue/composition-api'
import useHttp from '@/comp-functions/useHttp'
import { formatCurrency } from '@/utils/formatter'
import jsPDF from 'jspdf'
import router from '@/router'
import angkaTerbilang from '@develoka/angka-terbilang-js'
import moment from 'moment'
// import useLocalization from '@/comp-functions/utils/useLocalization'

export default {
  setup() {
    const { $get, $post, $delete } = useHttp()
    const paramsId = router.currentRoute.params.id

    const invoice = ref({
      company: {
        name: ''
      },
      contact: {
        pic_name: ''
      },
      payments: [
        {
          master: {
            code: '',
            description: ''
          }
        }
      ]
    })

    const getDataPreview = async () => {
      const { data } =  await $get({
        url: `transaction/purchase/request/${paramsId}`
      })
      invoice.value = data
    }

    onMounted(async () => {
      moment.locale('ID')
      await getDataPreview()
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: 'a4'
      });

      doc.setProperties({
        title: "Purchase Request"
      });

      let xPos = 10
      let yPos = 10

      let xReactPos = 10
      let yReactPos = 10

      //revisi
      doc.rect(xReactPos + 140, yReactPos + 6, 24, 5)
      doc.rect(xReactPos + 164, yReactPos + 6, 24, 5)
      doc.setFontSize(9).setFont('','')
      doc.text("Revisi", xReactPos + 142, yReactPos + 10)
      doc.text("03", xReactPos + 166, yReactPos + 10)

      // tanggal
      doc.rect(xReactPos + 140, yReactPos + 11, 24, 5)
      doc.rect(xReactPos + 164, yReactPos + 11, 24, 5)
      doc.setFontSize(9).setFont('','')
      doc.text("Tanggal", xReactPos + 142, yReactPos + 15)
      doc.text("01/03/2015", xReactPos + 166, yReactPos + 15)

      // No
      doc.rect(xReactPos + 140, yReactPos + 16, 24, 5)
      doc.rect(xReactPos + 164, yReactPos + 16, 24, 5)
      doc.setFontSize(9).setFont('','')
      doc.text("No", xReactPos + 142, yReactPos + 20)
      doc.text("FORM-PRC-03", xReactPos + 166, yReactPos + 20)

      doc.setFontSize(10).setFont('','bold')
      //Company name
      doc.text("PT. Pandu Siwi Sentosa", xPos, yPos)
      doc.setFontSize(8).setFont('','')
      yPos += 4
      //company address
      doc.text("Jalan Raya Bekasi Timur KM 18 no 30", xPos, yPos)
      yPos += 4
      doc.text("Jakarta Timur 13930", xPos, yPos)
      doc.setFontSize(10).setFont('','bold')
      yPos += 4
      doc.text("Purchase Departement", xPos, yPos)
      doc.setFontSize(8).setFont('','')
      yPos += 4
      doc.text("Telp : (021) 460 2993 ext 128", xPos, yPos)
      yPos += 4
      doc.text("Fax  : (021) 460 7625", xPos, yPos)

      doc.setFontSize(20).setFont('','bold')
      xPos += 70
      yPos += 10
      doc.text("Purchase Request", xPos, yPos)

      //name to 1
      doc.setFontSize(8).setFont('','bold')
      xPos -= 70
      yPos += 10
      doc.text("Kepada Yth", xPos, yPos)

      doc.setFontSize(8).setFont('','')
      xPos += 20
      doc.text(":", xPos, yPos)

      xPos += 5
      doc.text(`${invoice.value.pic_name}`, xPos, yPos)
      yPos += 1
      doc.line(xPos, yPos, 75, yPos)

      //name to 2
      yPos += 5
      doc.text("", xPos, yPos)
      yPos += 1
      doc.line(xPos, yPos, 75, yPos)

      //telp
      doc.setFontSize(8).setFont('','bold')
      xPos -= 25
      yPos += 5
      doc.text("No Telp", xPos, yPos);

      doc.setFontSize(8).setFont('','')
      xPos += 20
      doc.text(":", xPos, yPos);

      xPos += 5
      doc.text(`${invoice.value.pic_phone}`, xPos, yPos);
      yPos += 1
      doc.line(xPos, yPos, 75, yPos)

      //tanggal
      doc.setFontSize(8).setFont('','bold')
      xPos += 100
      yPos -= 13
      doc.text("Tanggal", xPos, yPos);

      doc.setFontSize(8).setFont('','')
      xPos += 20
      doc.text(":", xPos, yPos);

      xPos += 5
      const invDate = invoice.value.date
      doc.text(invDate ? moment(invDate, "DD/MM/YYYY").format('LL') : "", xPos, yPos);
      yPos += 1
      doc.line(xPos, yPos, 200, yPos)

      //No PO
      doc.setFontSize(8).setFont('','bold')
      xPos -= 25
      yPos += 5
      doc.text("No. PR", xPos, yPos)

      doc.setFontSize(8).setFont('','')
      xPos += 20
      doc.text(":", xPos, yPos)

      console.log(invoice)
      xPos += 5
      doc.text(`${invoice.value.code}`, xPos, yPos)
      yPos += 1
      doc.line(xPos, yPos, 200, yPos)

      //No. Fax
      doc.setFontSize(8).setFont('','bold')
      xPos -= 25
      yPos += 5
      doc.text("No. Fax", xPos, yPos)

      doc.setFontSize(8).setFont('','')
      xPos += 20
      doc.text(":", xPos, yPos)

      xPos += 5
      doc.text("021 - 6415263", xPos, yPos)
      yPos += 1
      doc.line(xPos, yPos, 200, yPos)


      doc.setFontSize(9).setFont('','bold')
      // No
      doc.rect(xReactPos, yReactPos + 65, 10, 5)
      doc.text("No.", xReactPos + 2, yReactPos + 68)
      doc.rect(xReactPos, yReactPos + 70, 10, 110)

      // spesifikasi
      doc.rect(xReactPos + 10, yReactPos + 65, 70, 5)
      doc.text("Spesifikasi Barang / Jasa", xReactPos + 30, yReactPos + 68)
      doc.rect(xReactPos + 10, yReactPos + 70, 70, 110)
      // Jumlah
      doc.rect(xReactPos + 80, yReactPos + 65, 24, 5)
      doc.text("Jumlah", xReactPos + 86, yReactPos + 68)
      doc.rect(xReactPos + 80, yReactPos + 70, 24, 110)
      // satuan
      doc.rect(xReactPos + 104, yReactPos + 65, 24, 5)
      doc.text("Satuan", xReactPos + 110, yReactPos + 68)
      doc.rect(xReactPos + 104, yReactPos + 70, 24, 110)
      // harga satuan
      doc.rect(xReactPos + 128, yReactPos + 65, 32, 5)
      doc.text("Harga Satuan", xReactPos + 135, yReactPos + 68)
      doc.rect(xReactPos + 128, yReactPos + 70, 32, 110)
      // Total harga
      doc.rect(xReactPos + 160, yReactPos + 65, 32, 5)
      doc.text("Total Harga", xReactPos + 167, yReactPos + 68)
      doc.rect(xReactPos + 160, yReactPos + 70, 32, 110)

      let xLoopPos = 10
      let yLoopPos = 79
      let hargaSatuan = 0
      invoice.value.details.forEach( async (field, index) => {
        yLoopPos+=5
        let quantitys = Number(field.quantity).toString()

        doc.text(`${index+1}`, xLoopPos + 3.5, yLoopPos)
        doc.text(`${field.product.name}`, xLoopPos + 12, yLoopPos)
        doc.text(`${quantitys}`, xLoopPos + 90, yLoopPos)
        doc.text(`${field.uom_id}`, xLoopPos + 113, yLoopPos)
        hargaSatuan = ((field.bill_amount ?? 0) / (field.product.quantity ?? 1))
        doc.text(isNaN(hargaSatuan) ? formatCurrency(0) : formatCurrency(hargaSatuan), xLoopPos + 130, yLoopPos)
        doc.text(formatCurrency(field.bill_amount ?? 0), xLoopPos + 162, yLoopPos)
      })

      // kotak total
      doc.rect(xReactPos, yReactPos + 180, 128, 18)

      doc.setFontSize(9).setFont('','bold')
      doc.rect(xReactPos + 128, yReactPos + 180, 32, 6)
      doc.text("PPN", xReactPos + 130, yReactPos + 184)

      doc.rect(xReactPos + 128, yReactPos + 186, 32, 6)
      doc.text("Ongkos Kirim", xReactPos + 130, yReactPos + 190)

      doc.rect(xReactPos + 128, yReactPos + 192, 32, 6)
      doc.text("Total Jumlah", xReactPos + 130, yReactPos + 196)

      doc.rect(xReactPos + 160, yReactPos + 180, 32, 6)
      doc.text(`${formatCurrency(invoice.value.sum_of_tax_amount || 0)}`, xReactPos + 162, yReactPos + 184)

      doc.rect(xReactPos + 160, yReactPos + 186, 32, 6)
      doc.text(`RP. 0`, xReactPos + 162, yReactPos + 190)

      doc.rect(xReactPos + 160, yReactPos + 192, 32, 6)
      doc.text(`${formatCurrency(invoice.value.sum_of_bill_amount || 0)}`, xReactPos + 162, yReactPos + 196)

      doc.rect(xReactPos, yReactPos + 198, 192, 6)
      doc.text(`Terbilang                : ${angkaTerbilang(Intl.NumberFormat().format(invoice.value.sum_of_bill_amount).replaceAll(',', ''))}`, xReactPos + 1, yReactPos + 202)

      doc.rect(xReactPos, yReactPos + 204, 192, 6)
      doc.text(`Cara Pembayaran : Tempo Satu Bulan`, xReactPos + 1, yReactPos + 208)

      //keterangan
      doc.text(`Keterangan : ..............................................................................................................`, xReactPos + 1, yReactPos + 216)

      //Catatan
      doc.text(`Catatan : `, xReactPos + 1, yReactPos + 222)

      doc.text(`1.  Mohon kirimkan barang/jasa sebelum tanggal                               ............`, xReactPos + 6, yReactPos + 228)

      doc.text(`2.  Cantumkan No. PO didalam tagihan/invoice dan surat jalan.`, xReactPos + 6, yReactPos + 234)

      doc.text(`3.  Mohon barang/jasa tersebut diatas dikirim/dikerjakan ke : `, xReactPos + 6, yReactPos + 240)

      doc.text(`    Alamat             :`, xReactPos + 6, yReactPos + 246)
      doc.line(xReactPos + 32, yReactPos + 246, 117, yReactPos + 246)

      doc.line(xReactPos + 32, yReactPos + 252, 117, yReactPos + 252)

      doc.text(`    PIC & No telp :`, xReactPos + 6, yReactPos + 258)
      doc.line(xReactPos + 32, yReactPos + 258, 117, yReactPos + 258)

      doc.text(`    No PR               :`, xReactPos + 6, yReactPos + 264)
      doc.text(`${invoice.value.code}`, xReactPos + 32, yReactPos + 263)
      doc.line(xReactPos + 32, yReactPos + 264, 117, yReactPos + 264)

      //tanda tangan
      doc.rect(xReactPos + 123, yReactPos + 216, 70, 6)
      doc.text(`Jakarta, ${moment().format('LL')}`, xReactPos + 142, yReactPos + 220)
      doc.rect(xReactPos + 123, yReactPos + 222, 70, 30)
      doc.text(`Mohd Zaki`, xReactPos + 150, yReactPos + 250)
      doc.rect(xReactPos + 123, yReactPos + 252, 70, 6)
      doc.text(`  Director`, xReactPos + 150, yReactPos + 256)

      doc.save(`${invoice.value.code}.pdf`, {returnPromise: true}).then(this.$router.replace({ name: 'apps-transactions-sales-invoice-list' }))
    })

    return {
      // $t,
      formatCurrency,
      paramsId,
      invoice,
      delete: $delete,
      post: $post
    }
  },
}
</script>
